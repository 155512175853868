import '../style/Settings.css';

import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { fetchPostProcess, fullError, showDangerToast, showSuccessToast } from '../../services/util';
import { changePasswordFetch, createRestaurantFetch, fetchCheckIfAdmin, fetchUserIdByEmail } from '../../services/api';
import { registerFetch } from '../../services/baseApi';

function Settings(props) {

    const { t } = useTranslation();
    var [oldPasswordValue, setOldPasswordValue] = useState('');
    var [newPasswordValue, setNewPasswordValue] = useState('');
    var [confirmPasswordValue, setConfirmPasswordValue] = useState('');
    var [restaurantNameValue, setRestaurantNameValue] = useState('');
    var [addressValue, setAddressValue] = useState('');
    var [restaurateurEmailValue, setRestaurateurEmailValue] = useState('');

    const createRestaurantEvent = async () => {
        try {
            props.setShowLoading(true);
            const userIdResult = await fetchUserIdByEmail(restaurateurEmailValue);
            if (userIdResult.result === 'OK') {
                props.setShowLoading(false);
                fetchPostProcess(
                    () => createRestaurantFetch(restaurantNameValue, addressValue, userIdResult.data._id),
                    () => resetRestaurantForm(),
                    t('operationCompletedSuccessfully'),
                    t('errorDuringTheOperation'),
                    props
                );

            } else {
                props.setShowLoading(false);
                showDangerToast(t('errorDuringTheOperation'));
            }
        } catch (error) {
            props.setShowLoading(false);
            if (error.response.data.msg === "User not found") {
                fullError(t('userNotFound'), error);
            }
        }
    }

    const resetRestaurantForm = () => {
        setRestaurantNameValue('');
        setAddressValue('');
        setRestaurateurEmailValue('');
    }

    /** Check */
    var [isGod, setIsGod] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchCheckIfAdmin();
                if (result.result === 'OK') {
                    setIsGod(result.data.isAdmin);
                }
            } catch (error) {
                fullError(t('anErrorOccurred'), error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        props.setPageTo('settings');
    }, [props.setPageTo]);
    const saveChanges = async () => {
        if (newPasswordValue.trim() !== '' && confirmPasswordValue.trim() !== '' && oldPasswordValue.trim() !== '') {
            if (newPasswordValue === confirmPasswordValue) {
                if (await window.confirm('')) {
                    try {
                        props.setShowLoading(true);
                        const result = await changePasswordFetch(oldPasswordValue, newPasswordValue, confirmPasswordValue);
                        if (result.result === 'OK') {
                            resetForm();
                            showSuccessToast(t('changesSavedCorrectly'));
                        }
                    } catch (error) {
                        if (error.response.data.msg === "Wrong Password") {
                            showDangerToast(t('oldPasswordWrong'));
                        } else if (error.response.data.msg === "New password is equals to old password") {
                            fullError(t('newPasswordEqualsToOld'), error);
                        } else {
                            fullError(t('anErrorOccurredDuringSaveChanges'), error);
                        }
                    } finally {
                        props.setShowLoading(false);
                    }
                }
            } else {
                showDangerToast(t('passwordAndConfirmDoNotMatch'));
            }
        } else {
            showDangerToast(t('enterAllParameters'));
        }
    }
    const resetForm = () => {
        setOldPasswordValue('');
        setNewPasswordValue('');
        setConfirmPasswordValue('');
    }
    return (
        <div id="settingsBox" className="container settings settingsBox">
            <fieldset>
                <legend>{t('changePassword')}</legend>
                <hr />
                <div className="mb-3">
                    <label htmlFor="oldPassword" className="form-label">{t('oldPassword')}</label>
                    <input type="password" className="form-control" id="oldPassword"
                        value={oldPasswordValue} onChange={(event) => setOldPasswordValue(event.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">{t('newPassword')}</label>
                    <input type="password" className="form-control" id="newPassword"
                        value={newPasswordValue} onChange={(event) => setNewPasswordValue(event.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">{t('confirmPassword')}</label>
                    <input type="password" className="form-control" id="confirmPassword"
                        value={confirmPasswordValue} onChange={(event) => setConfirmPasswordValue(event.target.value)} />
                </div>
                <button onClick={() => saveChanges()} className="btn btn-primary">{t('saveChanges')}</button>
            </fieldset>
            {isGod === true && <fieldset>
                <legend>{t('addRestaurant')}</legend>
                <hr />
                <div className="mb-3">
                    <label htmlFor="restaurantName" className="form-label">{t('name')}</label>
                    <input value={restaurantNameValue} type="text" className="form-control" id="restaurantName" onChange={(event) => setRestaurantNameValue(event.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="address" className="form-label">{t('address')}</label>
                    <input value={addressValue} type="text" className="form-control" id="address" onChange={(event) => setAddressValue(event.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="restaurateurEmail" className="form-label">{t('restaurateurEmail')}</label>
                    <input value={restaurateurEmailValue} type="text" className="form-control" id="restaurateurEmail" onChange={(event) => setRestaurateurEmailValue(event.target.value)} />
                </div>
                <button onClick={() => createRestaurantEvent()} className="btn btn-primary">{t('add')}</button>
            </fieldset>}
        </div>
    );
}
export default Settings;