
import { toast, Zoom } from 'react-toastify';

import Cookies from 'js-cookie';
import {fetchRequestNewWSToken, logoutFetch} from './api';
import { sendMessageToOtherTabs } from "./broadcastManager";
import {fetchPost} from "./baseApi";

// const WS_SERVER = 'ws://ayce-ws-7qouqbu2da-ew.a.run.app/';
const WS_SERVER = process.env.REACT_APP_WS_SERVER;
export const AUTOLOGIN_CODE = 'Mtt2Few6823PRDLvajvhfY79Po4xu5d1qAJHUPGYBshWLWkS7V';

//const WS_SERVER = 'ws://localhost:3001/';

export const listenMessage = async (callbackFunction, clientId, messageToCheck, closeConnection = true) => {
    let ws = null;
    let attempts = 0;
    let stopReconnection = false; // Flag per interrompere le riconnessioni su close() o cambio pagina
    let storedSessionId = null;

    return new Promise(async (resolve, reject) => {
        const createWebSocket = async (sessionId = null) => {
            // Evita di ricreare un WebSocket se stopReconnection è attivo
            if (stopReconnection) {
                //console.log("Reconnection prevented due to intentional close.");
                return;
            }

            let wsUrl = new URL(WS_SERVER);

            if (sessionId) {
                wsUrl.searchParams.set('sessionId', sessionId);
            }

            let wsToken = await fetchRequestNewWSToken();

            wsUrl.searchParams.set('wsToken', wsToken.data.wsToken);
            ws = new WebSocket(wsUrl.toString());

            ws.onopen = () => {
                //console.log("WebSocket connection established.");

                ws.send(JSON.stringify({
                    "type": "frontend",
                    clientId
                }));
            };

            ws.onerror = (error) => {
                //console.error("WebSocket error:", error);
                reject(error); // Rigetta la Promise in caso di errore
            };

            ws.onmessage = async (message) => {
                const parsedMessage = JSON.parse(message.data);
                if (parsedMessage.msg === 'session-init' && parsedMessage.sessionId) {
                    storedSessionId = parsedMessage.sessionId;
                    attempts = 0; // Resetta il conteggio dei tentativi sulla connessione riuscita
                }
                if (parsedMessage.msg === messageToCheck) {
                    //console.log("Message received:", parsedMessage);

                    // Esegui il callback
                    if (!!parsedMessage.other) {
                        callbackFunction.apply(null, [parsedMessage.other]);
                    } else {
                        callbackFunction.apply();
                    }

                    if (closeConnection === true) {
                        stopReconnection = true; // Segna la chiusura come intenzionale
                        ws.close(1000, "Normal closure"); // Chiudi il WebSocket
                        resolve(parsedMessage); // Risolvi la Promise
                    } else {
                        addUnloadListener();
                    }
                }
            };

            ws.onclose = async (event) => {
                //console.warn("WebSocket connection closed.");

                // Prevenzione riconnessione se "stopReconnection" è attivo
                if (stopReconnection) {
                    //console.log("WebSocket intentionally closed. No further reconnections.");
                    return;
                }

                //console.warn("Attempting to reconnect...");
                if (event.code === 1006) { //Retry solo per i timeout Cloud Run ed errori simili
                    if (attempts < 1) {
                        attempts++;
                        await createWebSocket(storedSessionId); // Riprova la connessione
                    } else {
                        //console.error("Max reconnection attempts reached.");
                        reject(new Error("Unable to reconnect after multiple attempts."));
                    }
                }
                else {
                    reject(new Error(`WebSocket connection closed with code ${event.code}`));
                }
            };
        };

        const addUnloadListener = () => {
            const closeWebSocket = () => {
                if (ws.readyState === WebSocket.OPEN) {
                    stopReconnection = true; // Impedisce riconnessioni future
                    ws.close(1001, "Browser tab closing");
                }
            };

            window.addEventListener('beforeunload', closeWebSocket);

            ws.onclose = () => {
                window.removeEventListener('beforeunload', closeWebSocket);
            };
        };

        // Avvia la connessione iniziale
        await createWebSocket();
    });
};

export const sendMessage = async (toClientId, messageToSend, other = false) => {
    return new Promise(async (resolve, reject) => {
        let wsToken = await fetchRequestNewWSToken();

        let wsUrl = new URL(WS_SERVER);
        wsUrl.searchParams.set('wsToken', wsToken.data.wsToken);
        const ws = new WebSocket(wsUrl.toString());
        ws.onopen = () => {
            var objToSend = {
                msg: messageToSend, toClientId
            }
            if (other !== false) objToSend['other'] = other;
            ws.send(JSON.stringify(objToSend));
            ws.close(1000, "Normal closure");
        };

        ws.onerror = (error) => {
            reject(error);
        };
    });
};
export const fetchPostProcess = async (awaitFunction, okFunction, successMessage, errorMessage, props) => {
    try {
        props.setShowLoading(true);
        const postFetch = await awaitFunction();
        if (postFetch.result === 'OK') {
            okFunction();
            showSuccessToast(successMessage);
        } else {
            fullError(errorMessage, postFetch);
        }
    } catch (error) {
        fullError(errorMessage, error);
    } finally {
        props.setShowLoading(false);
    }
}

export const getOrderIdByLongId = (longId) => {
    const indexOfHyphen = longId.indexOf('-');

    if (indexOfHyphen !== -1) {
        // Se è presente un trattino, restituisci il testo dopo di esso
        return longId.substring(indexOfHyphen + 1);
    } else {
        // Se non c'è nessun trattino, restituisci una stringa vuota
        return "";
    }
}

export const fullError = (text, errorObj = false) => {
    console.error(text, errorObj);
    showDangerToast(text);
}
export const showDangerToast = (message, autoClose = 1500) => {
    toast.error(message, {
        position: "top-center",
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
    })
}
export const showSuccessToast = (message, autoClose = 1500) => {
    toast.success(message, {
        position: "top-center",
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
    })
}
export const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export const logout = async () => {
    try {
        const resultFetch = await (logoutFetch);
    } catch (error) {
        fullError('Si è verificato un errore...', error);
    } finally {
        Cookies.remove('userId');
        Cookies.remove('username');
        Cookies.remove('rid');
        localStorage.removeItem('isAdmin');
        Cookies.remove('xAuthToken');
        Cookies.remove('jwtrt');
        Cookies.remove('isLoggedIn');
        localStorage.removeItem('menuTypeSelected');
        sendMessageToOtherTabs("reload_now");
        window.location.reload();
    }
}

export const getHour = (timestamp) => {
    const dateString = timestamp;
    const dateObject = new Date(dateString);

    const formattedTime = new Intl.DateTimeFormat('it-IT', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Europe/Rome', // Fuso orario dell'Italia
    }).format(dateObject);

    return formattedTime;
}
