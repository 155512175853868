
import { fetchGet, fetchPost, fetchFormData, fetchDelete } from './baseApi';

import Cookies from 'js-cookie';

export const getRestaurant = () => Cookies.get('rid');
export const getGuestLoginId = () => Cookies.get('guestLoginId');

/** GET */
export const fetchCheckIfRestaurateur = async () => {
    return fetchGet("/checkIfRestaurateur", 'fetchCheckIfRestaurateur');
};
export const fetchRestaurantData = async () => {
    return fetchGet("/getRestaurantData?rid=" + getRestaurant(), 'fetchRestaurantData');
};
export const fetchMenuType = async (type) => {
    return fetchGet("/getMenu?mid=" + type._id, 'fetchMenuType');
};
export const fetchMenuTypeList = async () => {
    return fetchGet("/getMenus?rid=" + getRestaurant(), 'fetchMenuTypeList');
};
export const fetchReadyOrders = async () => {
    return fetchGet("/getOrders?getReady=true&includeWithoutState=true&rid=" + getRestaurant(), 'fetchReadyOrders');
};
export const fetchOrderList = async () => {
    return fetchGet("/getOrders?rid=" + getRestaurant(), 'fetchOrderList');
};
export const fetchOrderListPrev = async (sid) => {
    return fetchGet("/getOrdersHistory?sid="+sid, 'fetchOrderListPrev');
};
export const fetchCustomerList = async () => {
    return fetchGet("/getClientQueue?rid=" + getRestaurant(), 'fetchCustomerList');
};
export const fetchTableList = async () => {
    return fetchGet("/getTables?rid=" + getRestaurant() + "&userData=1", 'fetchTableList');
};
export const fetchUserSessions = async (userId) => {
    return fetchGet("/getUserSessions?userId=" + userId, 'fetchUserSessions');
};
export const fetchUserRoles = async () => {
    return fetchGet("/getUserRoles", 'fetchUserRoles');
};
export const fetchCheckIfStaff = async () => {
    return fetchGet("/checkIfStaff?rid=" + getRestaurant(), 'fetchCheckIfStaff');
};
export const fetchCheckIfAdmin = async () => {
    return fetchGet("/checkIfAdmin", 'fetchCheckIfAdmin');
};
export const fetchRestaurants = async () => {
    return fetchGet("/getRestaurants?size=50&page=0", 'fetchRestaurants');
};
export const fetchRestaurantStaff = async () => {
    return fetchGet("/getRestaurantStaff?rid=" + getRestaurant(), 'fetchRestaurantStaff');
};
export const fetchUserIdByEmail = async (email) => {
    return fetchGet("/getUserByEmail?email=" + email, 'fetchUserIdByEmail');
};
export const fetchLegalNoticesOfRestaurant = async () => {
    return fetchGet("/getLegalNoticesOfRestaurant?rid=" + getRestaurant(), 'fetchLegalNoticesOfRestaurant');
};
/** POST */
export const fetchLoginFacebook = async (access_code) => {
    return fetchPost("/login/facebook", { access_code }, 'fetchLoginFacebook', true);
};
export const fetchLoginGoogle = async (access_code) => {
    return fetchPost("/login/google", { access_code }, 'fetchLoginGoogle', true);
};
export const fetchConfirmEmail = async (token) => {
    return fetchPost("/confirmEmail", { token }, 'fetchConfirmEmail', true);
};
export const sendOrderFetch = async (plates, menuId) => {
    return fetchPost("/sendOrder", { sessionId: Cookies.get('sessionId'), plates, menuId }, 'sendOrderFetch');
};
export const logoutFetch = async () => {
    return fetchPost("/logout", {}, 'logoutFetch');
};
export const openSessionFetch = async (tnumber, user_ids) => {
    return fetchPost("/openSession", { tnumber, user_ids, rid: getRestaurant() }, 'openSessionFetch');
};
export const closeSessionFetch = async (sessionId) => {
    return fetchPost("/closeSession", { sessionId }, 'closeSessionFetch');
};
export const deleteOrderFetch = async (orderId) => {
    return fetchPost("/deleteOrder", { orderId }, 'deleteOrderFetch');
};
export const deleteMealsFetch = async (orderId, platesList) => {
    return fetchPost("/deletePlatesFromOrder", { orderId, platesList }, 'deleteMealsFetch');
};
export const completeOrderFetch = async (orderId) => {
    return fetchPost("/completeOrder", { orderId }, 'completeOrderFetch');
};
export const completeMealsFetch = async (orderId, platesList) => {
    return fetchPost("/markPlatesAsCompleted", { orderId, platesList }, 'completeMealsFetch');
};
export const confirmOrderFetch = async (orderId) => {
    return fetchPost("/markOrderAsReady", { orderId }, 'confirmOrderFetch');
};
export const confirmMealsFetch = async (orderId, platesList) => {
    return fetchPost("/markPlatesAsReady", { orderId, platesList }, 'confirmMealsFetch');
};
export const createTableFetch = async (tableNumber, places) => {
    return fetchPost("/createTable", { tableNumber, places, restaurantId: getRestaurant() }, 'createTableFetch');
};
export const createMenuFetch = async (menuName, starting_price) => {
    return fetchPost("/createMenu", { menuName, starting_price, restaurant_id: getRestaurant() }, 'createMenuFetch');
};
export const updateMenuFetch = async (menuId, name, starting_price) => {
    return fetchPost("/updateMenu", { menuId, menu_data: { name, starting_price: parseInt(starting_price) } }, 'updateMenuFetch');
};
export const createCategoryFetch = async (menuId, categoryName) => {
    return fetchPost("/addCategoryToMenu", { menuId, categoryName }, 'createCategoryFetch');
};
export const createRestaurantFetch = async (restaurantName, restaurantAddress, restaurateurId) => {
    return fetchPost("/createRestaurant", { restaurantName, restaurantAddress, restaurateurId }, 'createRestaurantFetch');
};
export const updateCategoryFetch = async (menuId, categoryId, newCategoryName) => {
    return fetchPost("/updateMenuCategory", { menuId, categoryId, newCategoryName }, 'updateCategoryFetch');
};
export const deleteClientFetch = async (clientQueueId) => {
    return fetchPost("/removeFromClientQueue", { clientQueueId }, 'deleteClientFetch');
};
export const sendEmailFetch = async (subject, text) => {
    return fetchPost("/sendEmailToAdmin", { subject, text }, 'sendEmailFetch');
};
export const changePasswordFetch = async (oldPassword, newPassword, confirmPassword) => {
    return fetchPost("/changePassword", { oldPassword, newPassword, newPassword1: confirmPassword }, 'changePasswordFetch');
};
export const removeStaffFetch = async (userId, roleToRemove = 'staff') => {
    return fetchPost("/removeUserToRestaurantStaff", { userId, restaurantId: getRestaurant(), roleToRemove }, 'removeStaffFetch');
};
export const addStaffFetch = async (userId, roleToAssign = 'staff') => {
    return fetchPost("/addUserToRestaurantStaff", { userId, restaurantId: getRestaurant(), roleToAssign }, 'addStaffFetch');
};
export const setLegalNoticesOfRestaurant = async (text) => {
    return fetchPost("/setLegalNoticesOfRestaurant", { legalNotices: [{type: 'info', text}], restaurantId: getRestaurant()}, 'setLegalNoticesOfRestaurant');
};
export const fetchRequestNewWSToken = async () => {
    return fetchPost("/requestNewWSToken", {}, 'fetchRequestNewWSToken');
};
/** FORM DATA */
export const addNewMealFetch = async (name, ingredients, price, new_photo_file, menuId, categoryId) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('ingredients', ingredients);
    formData.append('price', price);
    formData.append('restaurant', getRestaurant());
    formData.append('new_photo', new_photo_file);
    formData.append('menuId', menuId);
    formData.append('categoryId', categoryId);

    return fetchFormData("/createPlateAndAddToMenu", formData, 'addNewMealFetch');
};
export const editMealFetch = async (plateId, name, ingredients, price, new_photo_file, menuId, categoryId) => {
    const formData = new FormData();
    formData.append('plateId', plateId);
    formData.append('name', name);
    formData.append('ingredients', ingredients);
    formData.append('price', price);
    formData.append('restaurant', getRestaurant());
    if (!!new_photo_file) formData.append('new_photo', new_photo_file);
    formData.append('menuId', menuId);
    formData.append('categoryId', categoryId);

    return fetchFormData("/updatePlateAndPrice", formData, 'editMealFetch');
};
/** DELETE */
export const deleteCategoryFetch = async (menuId, categoryId) => {
    return fetchDelete("/removeCategoryFromMenu", { menuId, categoryId }, 'deleteCategoryFetch');
};
export const deleteTableFetch = async (tableId) => {
    return fetchDelete("/deleteTable", { tableId }, 'deleteTableFetch');
};
export const deleteMenuFetch = async (menuId) => {
    return fetchDelete("/deleteMenu", { menuId }, 'deleteMenuFetch');
};
export const deleteMealFetch = async (plateId) => {
    return fetchDelete("/deletePlate", { plateId }, 'deleteMealFetch');
};