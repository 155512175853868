import './App.css';
import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

/** Loading and Feedback */
import ReactLoading from 'react-loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { sendMessageToOtherTabs } from './services/broadcastManager';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

/** Components */
import Meal from './components/Meal';
import EditMeal from './components/EditMeal';
/** Menu */
import Menu from './components/menu/Menu';
/** Pages */
import TableList from './components/pages/TableList';
import OrderList from './components/pages/OrderList';
import ReadyOrders from './components/pages/ReadyOrders';
import Settings from './components/pages/Settings';
import Staff from './components/pages/Staff';

/** Services */
import {getRestaurant, fetchCheckIfAdmin, fetchCheckIfRestaurateur, fetchCheckIfStaff, fetchLegalNoticesOfRestaurant, fetchMenuType, fetchMenuTypeList, fetchTableList, fetchUserSessions } from './services/api';
import MenuTypeModal from './components/modals/MenuTypeModal';
import CartModal from './components/modals/CartModal';

import { useTranslation } from 'react-i18next';
import './services/translations';

import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import { fullError, listenMessage, showSuccessToast } from './services/util';
import QrCode from './components/pages/QrCode';
import InfoModal from './components/modals/InfoModal';

function App() {

  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  var [meals, setMeals] = useState([]);
  var [originalMeals, setOriginalMeals] = useState([]);
  var [menuTypeList, setMenuTypeList] = useState([]);

  var [firstTimeSelected, setFirstTimeSelected] = useState(true);
  var [menuChanged, setMenuChanged] = useState(false);


  const [selectedType, setSelectedType] = useState(false);
  const [menuType, setMenuType] = useState(false);
  const [cart, setCart] = useState([]);
  const [page, setPage] = useState('home');
  const [confirmSelectedTrigger, setConfirmSelectedTrigger] = useState(false);
  const [deleteSelectedTrigger, setDeleteSelectedTrigger] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [assignedTableNumber, setAssignedTableNumber] = useState('');
  const [menuTypeSelected, setMenuTypeSelected] = useState((JSON.parse(localStorage.getItem('menuTypeSelected'))) || false);

  /** Modal */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isEditGlobal, setIsEditGlobal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  /** Ready */
  const [componentReady, setComponentReady] = useState(false);
  const [isMealsReady, setIsMealsReady] = useState(false);

  const [hasListened, setHasListened] = useState(false);

  const [menuChosen, setMenuChosen] = useState(false);
  const [isFetchCompleted, setIsFetchCompleted] = useState(false);


  const [isAllowedToClose, setIsAllowedToClose] = useState(true);
  const [refresh, setRefresh] = useState(false);

  /** Check */

  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);

  const [infoApi, setInfoApi] = useState(false);

  /** Per rimuovere avvisi */
  if (hasListened);

  window.confirm = async function (message, title = t("confirm")) {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t("confirm"),
      cancelButtonText: t("undo"),
      reverseButtons: true
    }).then((result) => {
      return result.isConfirmed;
    });
  };

  /** Data */

  const fetchMenuTypeListData = async () => {
    try {
      const menuTypeListData = await fetchMenuTypeList();
      await setMenuTypeList(menuTypeListData.data);
    } catch (error) {
      console.error('Errore fetchMenuTypeListData', error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchMenuTypeData = async (type) => {
    try {
      setShowLoading(true);
      const menuTypeData = await fetchMenuType(type);
      updateMeals(menuTypeData.data);
      setIsMealsReady(prevIsMealReady => !prevIsMealReady);
    } catch (error) {
      if (error.response.data.msg === "Invalid Token") return;
      fullError(t('anErrorOccurredWhileChangingMenu'), error);
    } finally {
      setShowLoading(false);
    }
  };
  const fetchTableListData = async () => {
    const tableListFetch = await fetchTableList();
    const username = Cookies.get('username');

    if (tableListFetch.result === 'OK') {
      tableListFetch.data.forEach(table => {
        if (!!table.users) {
          let found = table.users.find(u => u.username === username);
          if (!!found) {
            setAssignedTableNumber(table.number);
            setIsAssigned(true);
          }
        }
      });
    }
    try {
      const result = await fetchUserSessions(Cookies.get('userId'));
      if (isAssigned && result.result === 'OK') {
        Cookies.set('sessionId', result.data[0]._id, { expires: 7 });
      }
      setIsFetchCompleted(true);
    } catch (error) {
      fullError(t('anErrorOccurred'), error);
    } finally {
      setShowLoading(false);
    }
  };
  const fetchUserRolesProcess = async () => {
    setShowLoading(true);
    try {
      const result = await fetchCheckIfAdmin();
      if (result.data.isAdmin === true) {
        if (result.result === 'OK') {
          setIsAdmin(result.data.isAdmin);
        }
      } else {
        const resultRest = await fetchCheckIfRestaurateur();
        if (resultRest.data.isRestaurateur === true) {
          if (resultRest.result === 'OK') {
            setIsAdmin(resultRest.data.isRestaurateur);
            if (!getRestaurant() && !!resultRest.data.restaurants[0]) Cookies.set('rid', resultRest.data.restaurants[0], { expires: 7 });

          }
        } else {
          const resultIfStaff = await fetchCheckIfStaff();
          if (resultIfStaff.result === 'OK') {
            setIsAdmin(resultIfStaff.data.isStaff);
            setIsStaff(resultIfStaff.data.isStaff);
            if (!getRestaurant() && !!resultIfStaff.data.restaurants[0]) Cookies.set('rid', resultIfStaff.data.restaurants[0], { expires: 7 });

          }
        }
      }
      setIsFetchCompleted(true);
    } catch (error) {
      fullError(t('anErrorOccurred'), error);
    } finally {
      setShowLoading(false);
    }
  };
  const checkIfAssignedProcess = async () => {
    try {
      const result = await fetchUserSessions(Cookies.get('userId'));
      if (result.result === 'OK' && result.data.length > 0) {
        Cookies.set('rid', result.data[0].restaurant._id, { expires: 7 });
      }

      if (!!getRestaurant()) {
        await fetchTableListData();
        if (componentReady && (meals.length === 0)) {
          await fetchMenuTypeListData();
          if (!!menuTypeSelected) fetchMenuTypeData(menuTypeSelected);
        }
      }// else setIsFetchCompleted(true);
    } catch (error) {
      fullError(t('anErrorOccurred'), error);
    }
  }
  const fetchInfoData = async () => {
    const result = await fetchLegalNoticesOfRestaurant();
    if (result.result === 'OK' && result.data.length > 0) {
      return result.data.filter(i => i.type === 'info')[0].text;
    }
  }
  /** Effects */

  useEffect(() => {
    let pageToExclude = ['settings'];
    if (!!localStorage.getItem('categorySelected') && (isAdmin === false && ! pageToExclude.includes(page))) {

      setSelectedType(localStorage.getItem('categorySelected'));
      setPage(localStorage.getItem('categorySelected'));
    }
  });
  useEffect(() => {
    fetchUserRolesProcess();
  }, [isAdmin, refresh]);

  useEffect(() => {
    setHasListened(prevHasListened => {
      if (prevHasListened === false && isAssigned === true) {
        listenMessage((rid) => {
          refreshApp();
          closeAllModal();
          setCart([]);
          setIsAssigned(false);
          setPageTo('qrCode');
          sendMessageToOtherTabs("lazy_reload");
          Cookies.remove('sessionId');
          showSuccessToast(t('TheTableWasClosed'));
        }, 'table_closing_' + Cookies.get('sessionId'), 'isClosed');
        return true;
      } else {
        return false;
      }
    });
    if (componentReady === false) {
      setComponentReady(() => {
        return true;
      });
    }
  }, [isAssigned]);

  useEffect(() => {
    if (componentReady === false) {
      setComponentReady(() => {
        return true;
      });
    }
  }, [componentReady]);

  useEffect(() => {
    checkIfAssignedProcess();
  }, [componentReady, Cookies.get('rid')]);

  useEffect(() => {
    document.documentElement.classList.add('loggedIn');
    document.body.classList.add('loggedIn');
  }, []);

  useEffect(() => {
    // if (!isLoading && page === 'home' && !menuTypeSelected && (isAssigned === true || (isAdmin || isStaff))) {
      if (!isLoading && !menuTypeSelected && (isAssigned === true || (isAdmin || isStaff))) {
      setIsModalOpen(true);
    } else {
      setMenuType(menuTypeSelected);
    }
  }, [isLoading, page, menuTypeSelected, refresh, isAdmin]);

  useEffect(() => {
    const firstKey = Object.keys(meals)[0];
    if (Object.keys(meals).length > 0 && (page === 'home') && firstTimeSelected === true && menuChanged === false) {
      setSelectedType(firstKey);
      setPage(firstKey);
      setFirstTimeSelected(false);
    }
  }, [isMealsReady, page, meals, firstTimeSelected]);



  /** Page */
  const setPageTo = (page) => {
    setPage(page);
    setFirstTimeSelected(false);
  };
  const refreshApp = () => {
    setShowLoading(true);
    setPageTo('home');
    setFirstTimeSelected(true);
    setComponentReady(false);
    setRefresh(prevRefresh => !prevRefresh);
  };
  const closeAllModal = () => {
    setIsModalOpen(false);
    setIsCartModalOpen(false);
    setIsInfoModalOpen(false);
  };
  const chooseTypeMenu = (menuTypeParam) => {
    if (!!menuType && menuTypeParam._id !== menuType._id) {
      setCart([]);
      fetchMenuTypeData(menuTypeParam);
      setMenuType(menuTypeParam);
    } else if (!!menuType && menuTypeParam._id === menuType._id) {
      setMenuType(menuTypeParam);
    } else {
      fetchMenuTypeData(menuTypeParam);
      setMenuType(menuTypeParam);
    }
    let tempMenuTypeSelected = menuTypeParam;
    setMenuTypeSelected(tempMenuTypeSelected)
    localStorage.setItem('menuTypeSelected', JSON.stringify(tempMenuTypeSelected));

    if (!!tempMenuTypeSelected.categories && tempMenuTypeSelected.categories.length > 0) {
      setPageTo(tempMenuTypeSelected.categories[0].id);
      setSelectedType(function () { return tempMenuTypeSelected.categories[0].id });
      setMenuChanged(true);
    }

    setFirstTimeSelected(true);
    setIsAllowedToClose(true);
    setIsModalOpen(false);
  }
  /**  Meal */
  const updateMeals = (meals) => {
    let tempMeals = [];
    setOriginalMeals(meals);
    setMenuChosen(true);
    meals.categories.forEach(md => {
      md.plates.forEach(p => p.parentId = md.id); //passo l'id della categoria
      tempMeals[md.id] = md.plates;
    });
    setMeals(tempMeals);
  };
  const resetMeals = () => {
    if (!!menuTypeSelected) {
      setPage('home');
      setFirstTimeSelected(true);
      fetchMenuTypeData(menuTypeSelected);
    }
  };
  const changeMeals = (type) => {
    localStorage.setItem('categorySelected', type);
    setIsEditGlobal(false);
    setPageTo('home');
    setSelectedType(type);
  };
  /**
  * @param meal the meal to change
  * @param diff plus or minus one (or two, or three ecc.)?
  */
  const changeQuantityToCart = (meal, diff) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.meal.id === meal.id);
      if (existingItem) {
        // Se il pasto è già nel carrello, aggiorna la quantità
        return prevCart.map((item) =>
          item.meal.id === meal.id
            ? (
              item.quantity + diff > 0
                ? { ...item, quantity: item.quantity + diff }
                : null
            ) : item
        ).filter(Boolean);
      } else if (diff > 0) {
        // Se il pasto non è nel carrello, aggiungi un nuovo elemento
        return [...prevCart, { meal, quantity: 1 }];
      }
    });
  }


  /** Modal */
  const infoModalPreProcess = async () => {
    let tempInfoApi = await fetchInfoData();
    setInfoApi(tempInfoApi);
    setIsInfoModalOpen(true);
  }
  return (
    <div className="App">
      <main className={"mb-5 main " + (isMobile ? ' xs' : 'open')}>

        <Menu setSelectedType={setSelectedType} isFetchCompleted={isFetchCompleted} fetchMenuTypeData={fetchMenuTypeData}
          isAssigned={isAssigned} menuTypeSelected={menuTypeSelected}
          setMenuChosen={setMenuChosen} menuChosen={menuChosen} setIsModalOpen={setIsModalOpen}
          originalMeals={originalMeals} page={page} setShowLoading={setShowLoading} showLoading={showLoading} changeMeals={changeMeals}
          isAdmin={isAdmin} isStaff={isStaff} updateMeals={updateMeals} />

        <MenuTypeModal isStaff={isStaff} fetchMenuTypeListData={fetchMenuTypeListData} isAllowedToClose={isAllowedToClose}
          setIsAllowedToClose={setIsAllowedToClose} setMenuTypeSelected={setMenuTypeSelected}
          setFirstTimeSelected={setFirstTimeSelected} menuTypeSelected={menuTypeSelected} isAdmin={isAdmin}
          setShowLoading={setShowLoading} showLoading={showLoading} menuType={menuType} menuTypeList={menuTypeList}
          setMenuTypeList={setMenuTypeList} chooseTypeMenu={chooseTypeMenu} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

        <CartModal setShowLoading={setShowLoading} page={page}
          setPage={setPage} menuType={menuType} cart={cart} resetMeals={resetMeals} setCart={setCart} setPageTo={setPageTo}
          changeQuantityToCart={changeQuantityToCart} isCartModalOpen={isCartModalOpen} setIsCartModalOpen={setIsCartModalOpen} />

        <InfoModal isAdmin={isAdmin} isStaff={isStaff} fetchInfoData={fetchInfoData} infoApi={infoApi} setInfoApi={setInfoApi} isInfoModalOpen={isInfoModalOpen} setIsInfoModalOpen={setIsInfoModalOpen} setShowLoading={setShowLoading} />

        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          transition="Bounce"
        />
        {showLoading &&
          <div>
            <div className="fade modal-backdrop show"></div>

            <ReactLoading className="loader" type={"bubbles"}
              style={{
                position: 'fixed',
                width: '300px',
                zIndex: 9999,
                top: '30%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }} />

          </div>
        }

        <div className="album foodContainer py-4 py-sm-5 ps-0 ps-sm-2 bg-body-tertiary">
          <div className="container-fluid ps-0 contentBox">
            <Routes>
              {(isAssigned || isAdmin) &&
                <Route
                  path="/"
                  element={
                    <div id="meal-list" className="mealsBox row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3">
                      {meals[selectedType] && meals[selectedType].map(m => (
                        <Meal key={m.id} isStaff={isStaff} setIsEditGlobal={setIsEditGlobal} isEditGlobal={isEditGlobal}
                          fetchMenuTypeData={fetchMenuTypeData} menuType={menuType} cart={cart}
                          setShowLoading={setShowLoading} showLoading={showLoading}
                          changeQuantityToCart={changeQuantityToCart}
                          isAdmin={isAdmin} selectedType={selectedType}
                          setMeals={setMeals} meals={meals} {...m} />
                      )
                      )}
                      {(!!selectedType) &&
                        <EditMeal isStaff={isStaff} fetchMenuTypeData={fetchMenuTypeData} isEditGlobal={isEditGlobal}
                          setShowLoading={setShowLoading} showLoading={showLoading} isAdmin={isAdmin} menuType={menuType}
                          selectedType={selectedType} setMeals={setMeals} meals={meals} isNew={true} />
                      }
                    </div>
                  }
                />
              }
              <Route
                path="/settings"
                element={<Settings page={page} setPageTo={setPageTo} setShowLoading={setShowLoading} />}
              />
              {(isAssigned === false && isAdmin === false && isFetchCompleted === true) && <Route
                path="/qr-code"
                element={<QrCode setIsMenuModalOpen={setIsModalOpen} refreshApp={refreshApp} setPageTo={setPageTo} setShowLoading={setShowLoading} setIsAssigned={setIsAssigned} />}
              />}

              {isAdmin === true && <Route
                path="/staff"
                element={<Staff page={page} setPageTo={setPageTo} setShowLoading={setShowLoading} />}
              />}
              {isAdmin === true && <Route
                path="/order-list"
                element={<OrderList setShowLoading={setShowLoading} isAdmin={isAdmin} setPageTo={setPageTo} confirmSelectedTrigger={confirmSelectedTrigger}
                  setConfirmSelectedTrigger={setConfirmSelectedTrigger} deleteSelectedTrigger={deleteSelectedTrigger} setDeleteSelectedTrigger={setDeleteSelectedTrigger} />}
              />
              }
              {isAdmin === true && <Route
                path="/ready-orders"
                element={<ReadyOrders setPageTo={setPageTo} setShowLoading={setShowLoading}
                  isAdmin={isAdmin} confirmSelectedTrigger={confirmSelectedTrigger}
                  setConfirmSelectedTrigger={setConfirmSelectedTrigger} deleteSelectedTrigger={deleteSelectedTrigger} setDeleteSelectedTrigger={setDeleteSelectedTrigger} />}
              />
              }
              {isAdmin === true &&
                <Route
                  path="/table-list"
                  element={<TableList setShowLoading={setShowLoading} showLoading={showLoading} setPageTo={setPageTo}
                    isAdmin={isAdmin} isStaff={isStaff} />}
                />
              }
              {/* Redirect to home when not admin */}
              {(isAdmin !== false || isStaff !== false || isAssigned !== false) &&
                <Route path='*' element={<Navigate to='/' />} />
              }
              {(isAssigned === false && isAdmin === false && isStaff === false && isFetchCompleted === true) &&
                <Route path='/' element={<Navigate to='/qr-code' />} />
              }
            </Routes>
          </div>
        </div>
        {page !== 'tableList' &&
          <div className={"position-fixed ordinaContainer py-1 px-2 px-sm-0 " + (isAdmin ? "isAdmin " : " ")}>
            {!isAdmin && isAssigned &&
              <div className="tableNumber d-flex justify-content-center">
                {t('table')} {assignedTableNumber}
                <span onClick={infoModalPreProcess} className="bi bi-info-circle ms-2 info"></span>
              </div>
            }
            {isAdmin &&
              <div className="tableNumber d-flex justify-content-center">
                <span onClick={infoModalPreProcess} className="bi bi-info-circle ms-2 info"></span>
              </div>
            }
            {(isAssigned || isAdmin) &&
              <div className="buttonBox me-sm-2">
                <button onClick={() => setIsModalOpen(true)} type="button" className="btn btn-primary changeMenu">
                  {t('changeMenu')}
                </button>
                {!isAdmin && cart.length > 0 &&
                  <>
                    <button onClick={() => setIsCartModalOpen(true)} type="button" className="ms-2 btn btn-primary changeMenu">
                      {t('cart')}
                    </button>
                  </>
                }
              </div>
            }
          </div>
        }
      </main>
    </div>
  );
}
export default App;
