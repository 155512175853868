
import MenuElem from './MenuElem';

import React, { useEffect, useState } from 'react';
import MenuAdmin from './MenuAdmin';
import { useTranslation } from 'react-i18next';
import { fetchRestaurantData, getRestaurant } from '../../services/api';
import { fullError } from '../../services/util';


function MenuList(props) {
  const { t } = useTranslation();
  const [restaurantName, setRestaurantName] = useState('');
  const [componentReady, setComponentReady] = useState(false);

  useEffect(() => {
    if (componentReady === false) {
      setComponentReady(() => {
        return true;
      });
    }
  }, []);
  useEffect(() => {
    if (componentReady && restaurantName === '') {
      if (!!getRestaurant()) fetchRestaurantDataProcess();
      // fetchRestaurantDataProcess();
    }
  }, [componentReady, getRestaurant()]);

  const fetchRestaurantDataProcess = async () => {
    try {
      const result = await fetchRestaurantData();
      if (result.result === 'OK') {
        setRestaurantName(result.data.name);
        document.title = 'Dodaja - ' + result.data.name;
      }
    } catch (error) {
      fullError(t('anErrorOccurred'), error);
    }
  };
  return (
    <>
      <div className="logo">
        <img src="/img/svg/logo.svg" />
        <h5 className="mt-2">{restaurantName}</h5>
      </div>
      <ul className={"navbar-nav nav-pills mt-1 " + (!props.isNavbar ? "menu flex-column mb-auto" : "")}>
        <MenuAdmin {...props} />
        {(props.isAssigned || props.isAdmin) &&
          <hr className="text-white" />
        }
        { /** CATEGORIES */
          (props.isAssigned || props.isAdmin) &&
          <>
            {!!props.menu.categories && props.menu.categories.length === 0 && <span className="text-center text-white p-2">{t('noCategoryPresent')}</span>}
            {props.menu.categories && props.menu.categories.map(mealType => (
              (
                (mealType.plates.length > 0 || props.isAdmin) &&
                <MenuElem key={'menu-elem-' + mealType.id + Math.floor(Math.random() * 10000) + new Date().getSeconds()} mealType={mealType} {...props} />
              )
            ))
            }
          </>
        }

        { /** ADMIN CREATE CATEGORY */
          props.isAdmin && !props.isStaff && <>
            <div className="input-group mt-3">
              <input onKeyUp={(event) => props.handleKeyUpAddCategory(event)} onChange={(event) => props.setNewCategoryValue(event.target.value)}
                type="text" className="form-control" value={props.newCategoryValue}
                placeholder={t('newCategoryPlaceholder')} disabled={props.showLoading} />
              <button onClick={props.addCategory} className="btn btn-primary" type="button" disabled={props.showLoading}><i className="bi bi-plus icon me-2"></i></button>

            </div>
          </>
        }
      </ul>
      {(props.isAssigned || props.isAdmin) &&
        <hr className="text-white" />
      }
    </>
  );
}
export default MenuList;